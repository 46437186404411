import React from 'react'
import { Link } from 'gatsby'
import { useStyletron } from 'baseui'
import { Paragraph1, Paragraph3 } from 'baseui/typography'
import { Button, SHAPE } from 'baseui/button'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Emoji from '../components/Emoji'

const NotFoundPage = () => {
  const [css] = useStyletron()

  const sectionClass = css({
    height: '80vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    margin: '2rem 0',
  })

  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className={sectionClass}>
        <div>
          <Emoji symbol={'😵'} size={50} />
          <h1>404: No encontramos esta ruta</h1>
          <Paragraph1>Lo sentimos esta ruta no existe.</Paragraph1>
          <Paragraph3>
            Pero estamos seguros que tienes interes en hacer un link de
            WhatsApp, haz clic en el siguiente boton y ¡crea uno gratis!
          </Paragraph3>
          <div style={{ marginTop: '2rem' }}>
            <Link to="/" style={{ color: '#fff', textDecoration: 'none' }}>
              <Button
                shape={SHAPE.pill}
                overrides={{
                  BaseButton: {
                    style: {
                      backgroundColor: '#00b66c',
                      boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px',
                    },
                  },
                }}
              >
                Crear mi propio wa.link
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
